import {
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpSmallSubheadlineRegular
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import { stateData } from '../../data/content/licensing';
import { Divider } from '../reskin-divider';
import {
  BodyWrapper,
  HeaderWrapper,
  LicenseGroup,
  LicenseWrapper,
  LicensingSection,
  SubHeadline
} from './elements';

const StateLicense = ({
  title,
  children,
  extra
}: PropsWithChildren<{ title: string; extra?: string | JSX.Element }>) => (
  <LicenseWrapper>
    <OwnUpSmallSubheadlineRegular variant="h1">{title}</OwnUpSmallSubheadlineRegular>
    <OwnUpBody variant="body1">{children}</OwnUpBody>
    {extra && (
      <OwnUpBody component="span" variant="body1">
        {extra}
      </OwnUpBody>
    )}
  </LicenseWrapper>
);

const ContentGridContainer = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpGridContainer variant="legacy">
    <OwnUpGridOffset xs={0} md={0} lg />
    <OwnUpGridItem xs={12} md={10} lg={8}>
      {children}
    </OwnUpGridItem>
    <OwnUpGridOffset xs={0} md={2} lg={3} />
  </OwnUpGridContainer>
);

export const Licensing = () => {
  return (
    <LicensingSection>
      <OwnUpGridWrapper>
        {/* Header */}
        <HeaderWrapper>
          <ContentGridContainer>
            <OwnUpHeadlineBook variant="h1">Licensing</OwnUpHeadlineBook>
            <SubHeadline>
              <StateLicense title="Own Up state disclosures">
                RateGravity, Inc.(d.b.a. Own Up.) is not a lender, but retains certain mortgage
                broker licenses to comply with various state requirements. Please see a summary of
                our current licensure below.
              </StateLicense>
            </SubHeadline>
          </ContentGridContainer>
        </HeaderWrapper>
        {/* Divider */}
        <Divider />
        {/* Body */}
        <BodyWrapper>
          <ContentGridContainer>
            <LicenseGroup>
              {stateData.map((item) => (
                <StateLicense key={item.state} title={item.state} extra={item.extra}>
                  {item.description}
                  {item.description && <br />}
                  {item.code}
                </StateLicense>
              ))}
            </LicenseGroup>
          </ContentGridContainer>
        </BodyWrapper>
      </OwnUpGridWrapper>
    </LicensingSection>
  );
};
