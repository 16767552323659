import { OwnUpThemeProvider, ownUpWhiteTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import { Ankle } from '../components/ankle';
import { Layout } from '../components/layout';
import { Licensing as LicensingComponent } from '../components/licensing';

export const Licensing = () => (
  <Layout>
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <LicensingComponent />
    </OwnUpThemeProvider>
    <Ankle />
  </Layout>
);

export default Licensing;
