import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const LicensingSection = styled.section`
  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.background.default};
`;

export const HeaderWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const SubHeadline = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  }
`;

export const BodyWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8)}px;
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(15)}px;
  }
`;

export const LicenseGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
`;

export const LicenseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2, 0)};
`;
